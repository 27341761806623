import React, { useEffect } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import AboutUsBanner from '../../components/about-us/AboutUsBanner';
import AboutUsOurMission from '../../components/about-us/AboutUsOurMission';
import AboutUsWhatWeDo from '../../components/about-us/AboutUsWhatWeDo';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import AboutUsTeam from '../../components/about-us/AboutUsTeam';
import AboutUsContactUs from '../../components/about-us/AboutUsContactUs';
import AboutUsOurFocusArea from '../../components/about-us/AboutUsOurFocusArea';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';

const AboutUs = () => {

  useEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if(anchor) {
      scrollIntoViewWithOffset(anchor);
    }
  }, [])

  return (
    <>
      <SEO
        title="About Us"
        description="Who we are and what we do."
      />
      <Layout>
        <AboutUsBanner />
        <AboutUsOurMission />
        <AboutUsOurFocusArea />
        <AboutUsWhatWeDo />
        {/* <TestimonialSectionFour /> */}
        <AboutUsTeam />
        <AboutUsContactUs />
      </Layout>
    </>
  )
}
export default AboutUs;